import React from "react"

import { StyledRealEstate } from "./RealEstate.styled"

import BackgroundImage from "gatsby-background-image"

import ImageGallery from "@votech/react-image-gallery"

import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

import Layout from "../../components/Layout/Layout"
import ContentWrapper from "../../components/ContentWrapper/ContentWrapper"
import H1 from "../../components/H1/H1"
import H2 from "../../components/H2/H2"
import RealEstateDetailsList from "../../components/RealEstateDetailsList/RealEstateDetailsList"
import Card from "../../components/Card/Card"
import CardGrid from "../../components/CardGrid/CardGrid"
// import ImageGallery from "../../components/ImageGallery/ImageGallery"

export const query = graphql`
  query SingleContentNieruchomosc($slug: String!) {
    contentfulNieruchomosc(slug: { eq: $slug }) {
      ...RealEstateData
    }
    allContentfulNieruchomosc(
      sort: { fields: publishedDate, order: DESC }
      limit: 3
    ) {
      ...RealEstateCard
    }
  }
`

const RealEstate = props => {
  // options for  documentToReactComponents
  const options = {
    renderText: text => {
      return text.split("\n").reduce((children, textSegment, index) => {
        return [...children, index > 0 && <br key={index} />, textSegment]
      }, [])
    },
  }

  const {
    contentfulNieruchomosc: {
      title,
      city,
      image: {
        fluid,
        file: { fileName },
      },
      richText: { raw },
      field1,
      field2,
      field3,
      field4,
      field5,
      field6,
      field7,
      field8,
      field9,
      field10,
      field11,
      field12,
      field13,
      field14,
      pictures,
    },
  } = props.data

  // creating new list of pictures that ImageGallery can read
  const imagesArray = pictures.map(object => {
    let properties = {
      src: object.file.url,
      alt: object.file.fileName,
    }

    return properties
  })

  // parsing the json
  const rawBlogPostObject = JSON.parse(raw)

  const mediaQueries = {
    tablet: "@media (min-width: 728px)",
    desktop: "@media (min-width: 1024px)",
  }

  return (
    <Layout>
      <StyledRealEstate>
        <BackgroundImage
          fluid={fluid}
          alt={fileName}
          style={{
            height: "560px",
            position: "relative",
            top: "60px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "50px",
          }}
        >
          <div className="real-estate-overlay" />
          <h1 className="headline-1">{city}</h1>
          <h2 className="headline-2">{title}</h2>
        </BackgroundImage>
        <ContentWrapper>
          {/* spacer div*/}
          <div style={{ height: "80px" }} />
          <div className="real-estate-content">
            <H1>{city}</H1>
            <div id="real-estate-info">
              <div id="real-estate-text">
                <H2 gold>{title}</H2>
                {documentToReactComponents(rawBlogPostObject, options)}
              </div>
              {/* <RealEstateDetailsList
                field1={field1}
                field2={field2}
                field3={field3}
                field4={field4}
                field5={field5}
                field6={field6}
                field7={field7}
                field8={field8}
                field9={field9}
                field10={field10}
                field11={field11}
                field12={field12}
                field13={field13}
                field14={field14}
              /> */}
            </div>
            <ImageGallery images={imagesArray} mediaQueries={mediaQueries} />
          </div>
        </ContentWrapper>
        {/* <iframe
          src={src}
          style={{ border: "0", width: "100%", height: "400px" }}
          allowFullScreen=""
          loading="lazy"
          title={title}
        /> */}
        <ContentWrapper>
          <H1
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              maxWidth: "1400px",
              width: "100%",
            }}
          >
            Podobne Inwestycje
          </H1>
          <CardGrid>
            {props.data.allContentfulNieruchomosc.edges.map((edge, index) => {
              const { slug, title2, image, category } = edge.node

              return (
                <Card
                  key={index}
                  to={`/oferty/${slug}`}
                  title={title2}
                  imgSrc={image && image.fluid}
                  imgAlt={image && image.file.fileName}
                  category={category}
                />
              )
            })}
          </CardGrid>
        </ContentWrapper>
      </StyledRealEstate>
    </Layout>
  )
}

export default RealEstate
