import styled from "styled-components"

export const StyledRealEstateDetailsList = styled.ul`
  flex: 1;
  list-style-type: none;
  padding: 0;
  li {
    display: flex;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid ${({ theme }) => theme.colors.black};
    margin-bottom: 25px;
  }
`
