import styled from "styled-components"

export const StyledRealEstate = styled.div`
  .real-estate-overlay {
    background: rgba(255, 255, 255, 0.4);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }

  .headline-1 {
    margin: 0;
    padding: 0;
    text-transform: uppercase;
    font-size: 2.5rem;
    border-bottom: 1px solid ${({ theme }) => theme.colors.black};

    ${({ theme }) => theme.media.tablet} {
      font-size: 2.8rem;
    }

    ${({ theme }) => theme.media.desktop} {
      font-size: 3rem;
    }
  }

  .headline-2 {
    margin: 6px 0 0 0;
    padding: 0;
    font-weight: 400;
    text-transform: uppercase;
    font-size: 1.5rem;

    ${({ theme }) => theme.media.tablet} {
      font-size: 1.6rem;
    }

    ${({ theme }) => theme.media.desktop} {
      font-size: 1.8rem;
    }
  }

  .real-estate-content {
    max-width: 1400px;
    margin: 0 auto 50px auto;
  }

  #real-estate-info {
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
    ${({ theme }) => theme.media.tablet} {
      flex-direction: row;
    }
    #real-estate-text {
      flex: 1;
      margin: 0 0 50px 20px;

      ${({ theme }) => theme.media.tablet} {
        margin: 0 0px 100px 50px;
        padding-right: 20px;
      }

      ${({ theme }) => theme.media.desktop} {
        padding-right: 100px;
      }

      h2 {
        font-size: 1.2rem;
        font-weight: bold;

        ${({ theme }) => theme.media.tablet} {
          font-size: 1.4rem;
        }
    
        ${({ theme }) => theme.media.desktop} {
          font-size: 1.5rem;
        }

        ::before {
          content: '';
          width: 10px;
          height: 2px;
          background: ${({ theme }) => theme.colors.primary};
          position: absolute;
          top: calc(50% - 1px);
          left: -20px;

          ${({ theme }) => theme.media.tablet} {
            left: -50px;
            width: 35px;
          }
        }
      }     
    }
  }
  }

  iframe {
    margin-bottom: 50px;

    ${({ theme }) => theme.media.tablet} {
      margin-bottom: 100px;
    }
  }
`
