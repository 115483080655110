import React from "react"
import Styled from "styled-components"

const ContentWrapper = ({ children }) => {
  const StyledWrapper = Styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 20px;

    ${({ theme }) => theme.media.tablet} {
      padding: 0 30px;
    }
  
    ${({ theme }) => theme.media.desktop} {
      padding: 0 60px;
    }
  `

  return <StyledWrapper>{children}</StyledWrapper>
}

export default ContentWrapper
